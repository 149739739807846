import { useState } from "react";
import { Line } from "../../../assets/svgs/svgs";
import QrImage from "../../../assets/imgs/qr.png";
import { MdEdit, MdDelete } from "react-icons/md";
import style from "./style.module.css";
import { UpdateDiscount } from "../../../api/api";
import DeleteConfirmation from "../../../components/DeleteConfirmation/DeleteConfirmation";

const ViewCoupon = ({
	data,
	user,
	setEdit,
	setFormDataDiscount,
	setView,
	update,
}) => {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const handleDeleteConfirm = async () => {
		await UpdateDiscount(data, "delete");
		setView((prev) => ({ ...prev, view: false }));
		update((prev) => !prev);
		setShowDeleteConfirmation(false);
	};

	const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	};

	return (
		<div className={`flex flex-col p-3 align-center justify-center`}>
			{showDeleteConfirmation && (
				<DeleteConfirmation
					onDelete={handleDeleteConfirm}
					onCancel={handleDeleteCancel}
				/>
			)}
			<div
				className={
					"flex items-center flex-col justify-center gap-4 p-6 rounded-lg bg-white border border-teal-400 w-[350px]"
				}
			>
				<div className={`flex flex-col gap-2 items-center`}>
					<div>
						<img
							src={`${process.env.REACT_APP_IMAGE_BASEURL}${data?.poster}`}
							className='rounded-3xl p-0 m-0 object-cover max-h-52'
							alt={user?.profilePhoto}
						/>
					</div>
				</div>
				<div className={`flex flex-col gap-4 text-center w-full`}>
					<p className={style.form_title}>{data.headline}</p>
					<p className={style.form__des}>{data.caption}</p>
					<p className={style.form_title}>{data.validTo}</p>
					<div className={`${style.cardStatus} items-center justify-center`}>
						<div
							className={`${style.cardCircle} ${
								data.status === "active" ? style.activeCircle : ""
							}`}
						></div>
						<div className={style.cardStatusText}>{data.status}</div>
					</div>
					<div className='overflow-hidden w-full flex justify-center'>
						<Line />
					</div>
					<div className={`flex flex-col gap-6 items-center`}>
						{/* <div>
							<img
								src={QrImage}
								alt='Qr Image'
								style={{ width: 60, height: 60 }}
							/>
						</div> */}
						{/* <div
              className={`flex flex-row gap-6 mt-3 items-center justify-between`}
            >
              <div>
                <p className={style.form__des}>Call To Action:</p>
              </div>
              <div>
                <button className={style.redeemButton}>
                  {data.callToAction ? data.callToAction : "Redeem Offer"}
                </button>
              </div>
            </div> */}
						{data?.howToRedeem && (
							<div
								// style={{ textWrap: "pretty" }}
								title={data?.howToRedeem}
								className='w-full overflow-hidden text-ellipsis whitespace-nowrap'
							>
								How To Redeem:-
								<br />
								{data?.howToRedeem}
							</div>
						)}
						{/* <div style={{textWrap:"wrap"}} className="w-[100%] overflow-hidden">Hows To Redeem:-{data.description}</div> */}
						<div className='flex items-start gap-2 flex-row w-100'>
							<button
								onClick={(_) => {
									setEdit(true);
									setFormDataDiscount(data);
								}}
								className={`button_primary`}
							>
								<MdEdit /> Edit
							</button>
							<button
								onClick={(_) => {
									setShowDeleteConfirmation(true);
								}}
								className={`button_danger`}
							>
								<MdDelete /> Delete
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewCoupon;
