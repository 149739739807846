import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Layout from "./components/molecules/Layout";
import { useStateContext } from "./contexts/ContextProvider";
import { addInterceptors, authenticate } from "./api/api";
import Threads from "./pages/adsmaster/Therads/Threads";
import AdsProfileComponent from "./pages/adsmaster/profiles/Profile";
import ProfileCampagins from "./pages/profileCampagins/ProfileCampagins";
import InstituteList from "./pages/institueList/InstituteList";
import KYCErrors from "./pages/adsmaster/kycErrors/kycErrors";
import EstuAds from "./pages/adsmaster/adsmanager/adsManager";
import DiscountCouponsPage from "./pages/adsmaster/discountCoupons/discountCoupons";
import AllowedUsers from "./pages/allowedUsers/AllowedUsers";
// import Mbanq from "./pages/mbanq/mbanq";
import NonSSNStudent from "./pages/nonSSNStudent/nonSSNStudent";
import UserWaitList from "./pages/waitlist/UserWaitlist";
import CountryList from "./pages/countryList/CountryList";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Deals from "./pages/deals/Deals";
import GroupCouponsPage from "./pages/deals/GroupCoupons/GroupCoupon";
import CSV from "./pages/csv/CSV";
import { useAuth } from "./contexts/AuthContext";
import CouponsPage from "./pages/coupons/Coupons";
import DashboardPage from "./pages/dashboard/DashboardPage";
import Comms from "./pages/comms/Comms";
import AdsManagerDemo from "./pages/adsmaster/adsManagerDemo";
import CustomerReportsPage from "./pages/customerReports/CustomerReportsPage";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import CampaignManagement from "./pages/campaigns/CampaignManagement";
import ReferralsPage from "./pages/Referrals/ReferralsPage";
import Media from "./pages/media/Media";
import SupportTicketManagement from "./pages/supportTicketManagement/SupportTicketManagement";
import { element } from "prop-types";

const App = () => {
  const { setIsLoading, setUser } = useStateContext();
  const navigate = useNavigate();
  const { userDepartment, setUserEmail } = useAuth();

  const department = userDepartment();
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    if (token) {
      addInterceptors();
      checkIsAuthenticated();
    } else {
      setIsLoading(false);
      navigate("/login");
    }
  }, []);

  const checkIsAuthenticated = async () => {
    try {
      const { data } = await authenticate();
      setUser(data.data.user);
      setUserEmail(data.data.user.email);
      setIsLoading(false);
    } catch (error) {
      navigate("/login");
      setIsLoading(false);
    }
  };

  const AllPrivateRoutes = [
    {
      path: "/ads",
      element: <AdsManagerDemo />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/waitlist",
      element: <UserWaitList />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/estu-ads",
      element: <EstuAds />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/instituteList",
      element: <InstituteList />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/countryList",
      element: <CountryList />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/kycerrors",
      element: <KYCErrors />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    { path: "/deals", element: <Deals />, department: ["ADMIN", "SUPERADMIN"] },
    { path: "/media", element: <Media />, department: ["ADMIN", "SUPERADMIN"] },
    {
      path: "/referrals",
      element: <ReferralsPage />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/deals/group-coupons/:groupId",
      element: <GroupCouponsPage />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/allowedUsers",
      element: <AllowedUsers />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    // { path: "/mbanq", element: <Mbanq />, department: ["ADMIN","SUPERADMIN",] },
    {
      path: "/international-student",
      element: <NonSSNStudent />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/threads",
      element: <Threads />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/adsprofile",
      element: <AdsProfileComponent />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/adsprofilecampagin",
      element: <ProfileCampagins />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/estu-ads/discount-coupons/:id",
      element: <DiscountCouponsPage />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/upload-csv",
      element: <CSV />,
      department: ["ADMIN", "SUPERADMIN", "MARKETING"],
    },
    {
      path: "/comms",
      element: <Comms />,
      department: ["ADMIN", "SUPERADMIN", "MARKETING"],
    },
    {
      path: "/coupons-management",
      element: <CouponsPage />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/dashboard",
      element: <DashboardPage />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/customer-reports",
      element: <CustomerReportsPage />,
      department: ["ADMIN", "SUPERADMIN", "VISITOR"],
    },
    {
      path: "/campaigns-management",
      element: <CampaignManagement />,
      department: ["ADMIN", "SUPERADMIN"],
    },
    {
      path: "/support",
      element: <SupportTicketManagement />,
      department: ["ADMIN", "SUPERADMIN"],
    },
  ];

  return (
    <div>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            {AllPrivateRoutes?.filter((curr) =>
              curr?.department?.includes(department)
            )?.map((itemRoute, idx) => {
              return (
                <Route
                  key={idx}
                  path={itemRoute?.path}
                  element={itemRoute?.element}
                />
              );
            })}
          </Route>
          <Route
            path="*"
            element={
              <div className="flex w-screen h-screen flex-col justify-center items-center">
                No Route for this User!!
              </div>
            }
          />
        </Routes>
      </LocalizationProvider>
    </div>
  );
};

export default App;
