import { useEffect, useState } from "react";
import { getCharges } from "../../../api/api";

const TicketTab = ({ tickets }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [chargesLoading, setChargesLoading] = useState(false);
	const [charges, setCharges] = useState({
		CONVENIENCE_FEE: 0,
		SALES_TAX: 0,
	});
	const [ticketTotal, setTicketTotal] = useState({
		total: 0,
		qty: 0,
		price: 0,
		grandTotal: 0,
		fee: 0,
		tax: 0,
	});

	const fetchCharges = async () => {
		setChargesLoading(true);

		try {
			const response = await getCharges();
			if (response?.success) {
				setCharges(response.data);
				calculateTotal(response.data);
			}
		} catch (error) {
		} finally {
			setChargesLoading(false);
		}
	};

	useEffect(() => {
		fetchCharges();
	}, []);

	const calculateTotal = (estuCharges) => {
		let total = 0,
			qty = 0,
			price = 0,
			fee = 0,
			tax = 0;

		if (tickets?.length > 0) {
			tickets.forEach((ticket) => {
				total += ticket.quantity * ticket.price;
				qty += parseInt(ticket.quantity);
				price += parseFloat(ticket.price);
			});

			if (total === 0) {
				fee = estuCharges.CONVENIENCE_FEE / 100;
				tax = estuCharges.SALES_TAX / 100;
			} else {
				console.log("total", estuCharges);
				fee = (estuCharges.CONVENIENCE_FEE / 100) * total;
				tax = (estuCharges.SALES_TAX / 100) * total;
			}

			setTicketTotal({
				total: parseFloat(total).toFixed(2),
				qty,
				price,
				grandTotal: parseFloat(total + fee + tax).toFixed(2),
				fee: parseFloat(fee).toFixed(2),
				tax: parseFloat(tax).toFixed(2),
			});
		} else {
			fee = estuCharges.CONVENIENCE_FEE / 100;
			tax = estuCharges.SALES_TAX / 100;
			setTicketTotal({
				total: 0,
				qty: 0,
				price: 0,
				grandTotal: fee + tax,
				fee: fee,
				tax: tax,
			});
		}
	};

	return (
		<>
			<div className={``}>
				<div className={`flex flex-col gap-2 overflow-x-auto`}>
					<table className='table-auto w-full'>
						<thead>
							<tr>
								{[
									"SI No.",
									"Ticket Type",
									"Ticket Quantity",
									"Ticket Price (USD)",
								].map((header, index) => (
									<th
										key={index}
										className='bg-[#f4f4f4] py-2 px-1 border border-[#ddd] text-sm font-medium font-montserrat text-center text-[#141414] whitespace-nowrap'
									>
										{header}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{tickets?.length > 0 ? (
								tickets?.map((ticket, index) => (
									<tr key={index}>
										<td className='border border-[#ddd] px-2 text-center'>
											{index + 1}
										</td>
										<td className='border border-[#ddd] p-2'>
											{ticket.ticketType}
										</td>
										<td className='border border-[#ddd] p-2'>
											{ticket.quantity}
										</td>
										<td className='border border-[#ddd] p-2'>{ticket.price}</td>
									</tr>
								))
							) : (
								<tr>
									<td
										colSpan={4}
										className='border border-[#ddd] px-2 py-4 text-center'
									>
										No tickets added
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div className='flex flex-col md:flex-row gap-4 justify-between mt-3 items-start md:items-end'>
					{parseFloat(ticketTotal.grandTotal) <= 0 ? (
						<div></div>
					) : (
						<button
							disabled={chargesLoading}
							type='button'
							onClick={() => setIsModalVisible(true)}
							className={`border-b border-b-primary text-primary font-bold text-sm hover:border-b-transparent transition-all`}
						>
							Order summary
						</button>
					)}
					<div className='flex gap-4'>
						<div className='flex flex-col gap-1 md:items-end'>
							<span className='text-sm text-inkDarkest font-medium'>
								Grand total: <strong>{ticketTotal.grandTotal}</strong>
							</span>
							<span className='text-[10px]'>
								(including convenience fee & sales tax)
							</span>
						</div>
					</div>
				</div>
			</div>
			{isModalVisible && (
				<div className='fixed inset-0 flex items-center justify-center z-50'>
					<div className='bg-white p-4 rounded-xl shadow-lg relative flex flex-col gap-3 border border-grayLightest'>
						<div className='flex justify-between items-center border-b border-b-grayLightest pb-3 gap-4'>
							<span className='text-sm font-bold text-inkDarkest'>
								Order summary
							</span>
							<button onClick={() => setIsModalVisible(false)}>
								{/* <CloseButton /> */}X
							</button>
						</div>
						<div className='flex flex-col gap-3 border-b border-b-grayLightest pb-3 text-xs font-medium text-inkDarkest'>
							<div className='flex justify-between items-center gap-4'>
								<span>Total ticket cost</span>
								<span>{ticketTotal.total}</span>
							</div>
							<div className='flex justify-between items-center gap-4'>
								<span>Convenience fee ({charges.CONVENIENCE_FEE}%)</span>
								<span>{ticketTotal.fee}</span>
							</div>
							<div className='flex justify-between items-center gap-4'>
								<span>Sales tax ({charges.SALES_TAX}%)</span>
								<span>{ticketTotal.tax}</span>
							</div>
						</div>
						<div className='flex justify-between items-center gap-4'>
							<span className='text-sm font-bold text-inkDarkest'>
								Grand total
							</span>
							<span>
								<strong>{ticketTotal.grandTotal}</strong>
							</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TicketTab;
