import { DndContext, closestCenter } from "@dnd-kit/core";
import { Loader } from "../../../components";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import SortableCard from "./SortableDiscountCard";
import EmptyCardComponent from "./EmptyCardComponent";

const CouponsTab = ({
	Data,
	active,
	handleDragEnd,
	loading,
	dataLoading,
	setShowApproveModel,
	setSelectedDataValue,
	setShowRejectionModel,
	setView,
}) => {
	return (
		<DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
			{loading ? (
				<div className={`flex flex-row justify-center align-center`}>
					<Loader />
				</div>
			) : (
				<>
					{dataLoading ? (
						<Loader />
					) : (
						<SortableContext
							items={Data.map((item) => item.id)}
							strategy={rectSortingStrategy}
						>
							<div
								className={
									Data?.length > 0
										? `md:flex md:flex-row grid grid-cols-1 gap-2 align-center h-auto py-3 flex-wrap`
										: `flex flex-row gap-2 align-center py-3 h-600`
								}
							>
								{Data.length > 0 ? (
									Data?.map((cardData, idx) => (
										<SortableCard
											key={cardData.id}
											cardData={cardData}
											cardIndexRank={idx + 1}
											type={active}
											setShowApproveModel={setShowApproveModel}
											setSelectedDataValue={setSelectedDataValue}
											setShowRejectionModel={setShowRejectionModel}
											view={setView}
											typeLabel={active === 2 ? "amazon" : ""}
										/>
									))
								) : (
									<EmptyCardComponent active={active} />
								)}
							</div>
						</SortableContext>
					)}
				</>
			)}
		</DndContext>
	);
};

export default CouponsTab;
