import style from "./style.module.css";

const EmptyCardComponent = ({ active }) => {
	return (
		<div className={style.emptycardContainer}>
			{active == 0
				? "No Discounts Created"
				: active == 1
				? "No Events Created"
				: "No Amazon Affiliate deals"}
		</div>
	);
};

export default EmptyCardComponent;
