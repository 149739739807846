import { useState } from "react";
import { Line } from "../../../assets/svgs/svgs";
import QrImage from "../../../assets/imgs/qr.png";
import style from "./style.module.css";
import { UpdateEvent } from "../../../api/api";
import { MdDelete, MdEdit } from "react-icons/md";
import DeleteConfirmation from "../../../components/DeleteConfirmation/DeleteConfirmation";
import TicketTab from "./TicketTab";

const ViewEventDetails = ({
	data,
	setEdit,
	setFormDataDiscount,
	setView,
	update,
}) => {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [tab, setTab] = useState(0);
	const handleDeleteConfirm = async () => {
		await UpdateEvent(data, "delete");
		setView((prev) => ({ ...prev, view: false }));
		update((prev) => !prev);
		setShowDeleteConfirmation(false);
	};

	const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	};

	return (
		<div className={`flex flex-col max-w-[720px] md:min-w-[640px]`}>
			<div className='border-b border-b-gray-300 pb-4 mb-4'>
				<div className='flex items-center gap-4 w-1/2'>
					<button
						onClick={(_) => setTab(0)}
						className={`flex gap-2 items-center text-sm whitespace-nowrap ${
							tab === 0 ? "text-primary font-semibold" : ""
						}`}
					>
						<span
							className={`text-white rounded-full text-xs w-4 h-4 flex items-center justify-center ${
								tab === 0 ? "bg-primary" : "bg-gray-400"
							}`}
						>
							1
						</span>
						Event Details
					</button>
					<hr className='border-gray-400 w-1/2' />
					<button
						onClick={(_) => setTab(1)}
						className={`flex gap-2 items-center text-sm whitespace-nowrap ${
							tab === 1 ? "text-primary font-semibold" : "text-gray-400"
						}`}
					>
						<span
							className={`text-white rounded-full text-xs w-4 h-4 flex items-center justify-center ${
								tab === 1 ? "bg-primary" : "bg-gray-400"
							}`}
						>
							2
						</span>
						Ticket Details
					</button>
				</div>
			</div>

			{showDeleteConfirmation && (
				<DeleteConfirmation
					onDelete={handleDeleteConfirm}
					onCancel={handleDeleteCancel}
				/>
			)}
			{tab === 0 && (
				<div
					className={`flex flex-col md:flex-row gap-10 align-start justify-between`}
				>
					<div className={`flex flex-col gap-6 text-left max-w-80`}>
						<div className={`flex flex-col gap-2 text-left`}>
							<p className={style.form__des}>Event</p>
							<p className={style.form_title}>{data?.headline}</p>
						</div>
						<div className={`flex flex-col gap-2 text-left`}>
							<p className={style.form__des}>Description</p>
							<p className={style.form_title}>{data?.description}</p>
						</div>
						<div className={`flex flex-col gap-2 text-left`}>
							<p className={style.form__des}>Start Date </p>
							<p className={style.form_title}>{data?.when}</p>
						</div>
						<div className={`flex flex-col gap-2 text-left`}>
							<p className={style.form__des}>End Date </p>
							<p className={style.form_title}>{data?.tillDate}</p>
						</div>
						<div className={`flex flex-col gap-2 text-left`}>
							<p className={style.form__des}>Time </p>
							<p className={style.form_title}>
								{data?.time} - {data?.till}
							</p>
						</div>
						<div className={`flex flex-col gap-2 text-left flex-wrap`}>
							<p className={style.form__des}>Location </p>
							<p className={`${style.form_title}`}>
								{data?.location
									? `${
											data?.location?.address1 ? data?.location?.address1 : ""
									  } ${
											data?.location?.address2 ? data?.location?.address2 : ""
									  } ${data?.location?.city ? data?.location?.city : ""} ${
											data?.location?.state ? data?.location?.state : ""
									  } ${
											data?.location?.country ? data?.location?.country : ""
									  } ${data?.location?.zipCode ? data?.location?.zipCode : ""}`
									: ""}
							</p>
						</div>
						<div className='flex items-start gap-2 flex-col md:flex-row w-100'>
							<button
								onClick={(_) => {
									setEdit(true);
									setFormDataDiscount(data);
								}}
								className={`button_primary`}
							>
								<MdEdit /> Edit
							</button>
							<button
								onClick={(_) => {
									setShowDeleteConfirmation(true);
								}}
								className={`button_danger`}
							>
								<MdDelete /> Delete
							</button>
						</div>
					</div>
					<div className={`flex flex-col gap-6 md:w-[300px]`}>
						<div className={`flex flex-col gap-2`}>
							<p className={style.form_title}>Ticket preview</p>
							<div
								className={`flex flex-col p-3 gap-3 border-[1px] border-gray-300 rounded-lg`}
							>
								<img
									style={{ width: 253, height: 147 }}
									src={`${process.env.REACT_APP_IMAGE_BASEURL}${data.poster}`}
									alt='ddd'
								/>
								<div className='overflow-hidden text-center overflow-ellipsis font-montserrat text-neutral-90 font-semibold text-16 leading-normal tracking-tight'>
									{data.headline}
								</div>
								<div className='text-center text-montserrat text-2c3030 text-14 font-normal tracking-tight leading-6 flex-wrap'>
									Venue:{" "}
									{data?.location
										? `${
												data?.location?.address1 ? data?.location?.address1 : ""
										  } ${
												data?.location?.address2 ? data?.location?.address2 : ""
										  } ${data?.location?.city ? data?.location?.city : ""} ${
												data?.location?.state ? data?.location?.state : ""
										  } ${
												data?.location?.country ? data?.location?.country : ""
										  } ${
												data?.location?.zipCode ? data?.location?.zipCode : ""
										  }`
										: ""}
								</div>
								<div className='text-center text-montserrat text-2c3030 text-14 font-normal tracking-tight leading-6'>
									Date: {data?.when} - {data?.tillDate}
								</div>
								<div className='text-center text-montserrat text-2c3030 text-14 font-normal tracking-tight leading-6'>
									Time: {data?.time} - {data?.till}
								</div>
								<div
									className={`flex flex-col align-center text-center w-[100%]`}
									style={{ alignItems: "center" }}
								>
									<div className={style.cardStatus}>
										<div
											className={`${style.cardCircle} ${
												data.status === "active" ? style.activeCircle : ""
											}`}
										></div>
										<div className={style.cardStatusText}>{data.status}</div>
									</div>
								</div>
								{/* <div className={`mt-2`}>
							<Line />
						</div>
						<div
							className={`flex flex-col align-center text-center w-[100%] mt-2`}
							style={{ alignItems: "center" }}
						>
							<img src={QrImage} alt='Qr' style={{ width: 60, height: 60 }} />
						</div> */}
							</div>
						</div>
					</div>
				</div>
			)}
			{tab === 1 && <TicketTab tickets={data?.tickets} />}
		</div>
	);
};

export default ViewEventDetails;
