import { UpdateEvent } from "../../../api/api";
import EditRowFormTicket from "./EditRowFormTicket";
import { TicketFormConfig } from "./formConfig";

const EditTicketForm = ({ formData, setFormData, show, reset }) => {
	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(formData);

		try {
			delete formData.updatedAt;
			delete formData.createdAt;
			await UpdateEvent(formData);
			await reset();
			show(false);
		} catch (error) {
			console.error(error);
			show(false);
		}
	};

	return (
		<EditRowFormTicket
			formConfig={TicketFormConfig}
			formData={formData}
			setFormData={setFormData}
			handleSubmit={handleSubmit}
		/>
	);
};

export default EditTicketForm;
