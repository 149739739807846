import { Loader } from "../../../components";
import SortableCard from "./SortableDiscountCard";
import EmptyCardComponent from "./EmptyCardComponent";

const EventsTab = ({
	Data,
	active,
	loading,
	dataLoading,
	setShowApproveModel,
	setSelectedDataValue,
	setShowRejectionModel,
	setView,
}) => {
	return loading ? (
		<div className={`flex flex-row justify-center align-center`}>
			<Loader />
		</div>
	) : (
		<>
			{dataLoading ? (
				<Loader />
			) : (
				<div
					className={
						Data?.length > 0
							? `md:flex md:flex-row grid grid-cols-1 gap-2 align-center h-auto py-3 flex-wrap`
							: `flex flex-row gap-2 align-center py-3 h-600`
					}
				>
					{Data.length > 0 ? (
						Data?.map((cardData, idx) => (
							<SortableCard
								key={cardData.id}
								cardData={cardData}
								cardIndexRank={idx + 1}
								type={active}
								setShowApproveModel={setShowApproveModel}
								setSelectedDataValue={setSelectedDataValue}
								setShowRejectionModel={setShowRejectionModel}
								view={setView}
								typeLabel={active === 2 ? "amazon" : ""}
							/>
						))
					) : (
						<EmptyCardComponent active={active} />
					)}
				</div>
			)}
		</>
	);
};

export default EventsTab;
