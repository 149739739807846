import React, { useCallback, useState } from "react";
// import helpIcon from "../../assets/svgs/helpIcon.svg";
import { useStateContext } from "../../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import { Logo, LogoutIcon } from "./../../assets/svgs/waitlist";
import { useAuth } from "../../contexts/AuthContext";
import { menuItems } from "./config";
import MenuChip from "./sidebar/MenuChip";
import classNames from "classnames";
import { GridMenuIcon } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";

const Sidebar = () => {
  const { menuToggle, setMenuToggle } = useStateContext();
  const { userDepartment } = useAuth();
  const navigate = useNavigate();
  const department = userDepartment();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // For mobile dropdown

  const handleMenuClick = useCallback(() => {
    setMenuToggle((prev) => !prev);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.reload();
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
  };

  return (
    <div className="relative z-50">
      <div
        className={classNames(
          `py-6 h-screen hidden  md:grid grid-rows-[auto,1fr,auto] fixed z-20 md:overflow-y-scroll overflow-y-hidden no-scroll transition-all duration-300
          ${menuToggle ? "toggled-sidebar-w" : "sidebar-w"}
          dark:bg-secondary-dark-bg bg-[#F8F9FA]`
        )}
      >
        <div className="flex flex-col w-full">
          <div className=" px-2 flex justify-start gap-10 items-center w-full">
            <span
              className="ml-[16px] cursor-pointer hover:bg-black hover:bg-opacity-5 duration-200 transition-all rounded-full p-1"
              onClick={handleMenuClick}
            >
              <GridMenuIcon />
            </span>
            {menuToggle && <Logo />}
          </div>
          <div className="sidebar-line md:block hidden"></div>
        </div>

        <div className="px-2 pb-4 w-full overflow-y-auto no-scroll">
          {menuItems
            ?.filter((menu) => menu?.department?.includes(department))
            ?.map((menu, idx) => (
              <MenuChip
                key={idx}
                navigate={navigate}
                url={menu?.url}
                name={menu?.name}
                icon={menu?.icon}
              />
            ))}
        </div>
        <div className="px-2">
          <button
            onClick={logout}
            className={`items-center p-2 py-[11px] mt-2 hover:shadow-md cursor-pointer flex rounded-[8px] group hover:bg-red-400 transition-all duration-300 hover:text-white w-full
             ${menuToggle ? " md:w-full" : " justify-start md:w-fit"}`}
          >
            <div
              className={`text-black rounded-[8px] shadow p-2 group-hover:bg-white group-hover:!text-white`}
            >
              <LogoutIcon />
            </div>
            {menuToggle && (
              <div className="pl-[14px] text-red-400 group-hover:text-white">
                Logout
              </div>
            )}
          </button>
        </div>
      </div>
      {/* Mobile Navbar */}
      <div className="md:hidden w-screen fixed top-0 left-0 right-0 bg-[#F8F9FA] dark:bg-secondary-dark-bg shadow-md z-50">
        <div
          className="flex justify-between items-center p-4 "
          style={{ height: "80px", zIndex: 10 }}
        >
          <span
            onClick={toggleMobileMenu}
            className="cursor-pointer text-[#2cccd3] text-2xl"
          >
            {mobileMenuOpen ? <CloseIcon /> : <GridMenuIcon />}
          </span>
          <Logo />
        </div>

        {/* Mobile Dropdown */}
        {mobileMenuOpen && (
          <div
            className="absolute top-full left-0 right-0 w-screen h-screen no-scroll bg-[#F8F9FA] dark:bg-secondary-dark-bg z-40 p-4 max-h-screen flex flex-col"
            style={{ color: "#2cccd3" }}
          >
            <div className="overflow-y-auto flex-1  mb-20">
              {menuItems
                ?.filter((menu) => menu?.department?.includes(department))
                ?.map((menu, idx) => (
                  <div key={idx} className="mb-4">
                    <MenuChip
                      navigate={navigate}
                      url={menu?.url}
                      name={menu?.name}
                      icon={menu?.icon}
                      onClick={toggleMobileMenu}
                    />
                  </div>
                ))}
              <div className="mb-4">
                <button
                  onClick={logout}
                  className="w-full flex flex-row text-left p-4 rounded-md hover:bg-red-400 bg-red-400 text-white shadow-md"
                >
                  <LogoutIcon className={"hover:text-white"} />
                  &nbsp; Logout
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
