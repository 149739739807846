import React, { useState } from "react";
import amazonLogo from "../../../assets/imgs/amazon-logo.png";
import { Line } from "../../../assets/svgs/svgs";
import QrImage from "../../../assets/imgs/qr.png";
import { MdEdit, MdDelete, MdDeleteOutline } from "react-icons/md";
import style from "./style.module.css";
import moment from "moment";
import DeleteConfirmation from "../../../components/DeleteConfirmation/DeleteConfirmation";
import { deleteGroupCouponsAPI } from "../../../api/api";
import { toast } from "react-toastify";

const ViewDiscountCard = ({ data, groupId, setView, getAPI, setEditable }) => {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [selected_id, setSelectedId] = useState(null);

	const handleDeleteConfirm = async () => {
		try {
			const response = await deleteGroupCouponsAPI({
				groupId,
				couponsIds: [selected_id],
			});
			console.log(response);
			setView(false);
			getAPI();
		} catch (error) {
			toast.error("Error while deleting Coupon");
		}
	};

	const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	};
	return (
		<div className={`flex flex-col p-3 align-center justify-center`}>
			{showDeleteConfirmation && (
				<DeleteConfirmation
					onDelete={handleDeleteConfirm}
					onCancel={handleDeleteCancel}
				/>
			)}
			<div
				className={
					"flex items-center flex-col justify-center gap-4 p-3 rounded-lg bg-white border border-teal-400 w-[350px]"
				}
			>
				<div className={`flex relative flex-col gap-2 items-center`}>
					{data?.poster && (
						<img
							src={amazonLogo}
							className='w-[64px] absolute left-0 bottom-0 p-2 rounded-10'
							alt={"logo"}
						/>
					)}
					<div>
						<img
							src={`${process.env.REACT_APP_IMAGE_BASEURL}${data?.poster}`}
							className='w-[298px] h-[136px] rounded-[10px]'
							alt={"poster"}
						/>
					</div>
				</div>
				<div className={`flex flex-col gap-4 text-center w-[100%] mt-7`}>
					<p className={style.form_title}>{data.headline}</p>
					<p className={style.form__des}>{data.caption}</p>
					<div>{moment(data.updatedAt).format("DD MMM, YYYY - h:mm a")}</div>

					<div className={`${style.cardStatus} items-center justify-center`}>
						<div
							className={`${style.cardCircle} ${
								data.status === "active" ? style.activeCircle : ""
							}`}
						></div>
						<div className={style.cardStatusText}>
							{data.status}&nbsp;{data.isStatus}
						</div>
					</div>
					<div className=' overflow-hidden flex justify-center'>
						<Line />
					</div>
					<div className={`flex flex-col gap-6 items-center`}>
						<div className='text-center border-b-[#2CCCD3] border-b-2 py-0 leading-3'>
							<a
								href={data.website}
								className='text-14 font-semibold text-[#2CCCD3]'
								target='_blank' // Open the link in a new tab
								rel='noopener noreferrer' // Improve security
							>
								Coupon Link
							</a>
						</div>
						{data?.description && (
							<div
								// style={{ textWrap: "pretty" }}
								title={data?.description}
								className='w-full overflow-hidden text-ellipsis whitespace-nowrap'
							>
								How To Redeem:-
								<br />
								{data?.description}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className='flex items-start justify-center gap-2 flex-row w-100 mt-8'>
				<button
					// onClick={(_) => {
					//   setEdit(true);
					//   setFormDataDiscount(data);
					// }}

					onClick={(_) => {
						setEditable(true);
						setSelectedId(data.id);
					}}
					className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex flex-row gap-2 items-center`}
				>
					<MdEdit /> Edit Coupon
				</button>
				{/* <button
          onClick={(_) => {
            setEditable(true);
            setSelectedId(data.id);
          }}
          className={`text-[#F6285F] font-semibold mx-auto px-4 text-14 rounded flex flex-row gap-2 items-center`}
        >
          <MdEdit color="#F6285F" />
        </button> */}
				<button
					onClick={(_) => {
						setShowDeleteConfirmation(true);
						setSelectedId(data.id);
					}}
					className={`text-[#F6285F] font-semibold mx-auto px-4 py-2 text-14 rounded flex flex-row gap-2 items-center`}
				>
					Delete Coupon <MdDeleteOutline color='#F6285F' />
				</button>
			</div>
		</div>
	);
};

export default ViewDiscountCard;
